import React, { useState } from "react";
import RequestService from "../../services/request.service";

import { toast } from 'react-toastify'

import moment from 'moment'
import 'moment/locale/es'

import { Table } from 'react-bootstrap'

import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import es from 'date-fns/locale/es';


const Censo_Reporte = () =>{



  const [startDate_seleccionada, setStartDateSeleccionada] = useState(new Date());
  const [endDate_seleccionada, setEndDateSeleccionada] = useState(new Date());

  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const onChange = (dates:any) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
  };



  const [ reportecapturistas, setReportecapturistas ] = useState<any>([])
  const [ envio_server, setSeEnvio ] = useState<boolean>(false)
  const cargarReportecapturistas = async()=>{
    try{
      setSeEnvio(true)
      let response : any = await RequestService.getEndPoint(`responsable_zona/reporte/${moment(startDate).format('YYYY-MM-DD')}/${moment(endDate).format('YYYY-MM-DD')}`)
      setReportecapturistas(response.data)
      setStartDateSeleccionada(startDate)
      setEndDateSeleccionada(endDate)
      setSeEnvio(false)
    }
    catch(e){
      setSeEnvio(false)
      toast.error('Problemas al intentar cargar')
    }
  }


  React.useEffect(() => {
    cargarReportecapturistas()
  }, []);


      return (
          <>
            <div className="container">


                    <h4>Reporte de Avances del Censo 2024</h4>
                    <hr/>


                    <div className="row">
                        <div className="col-12 col-md-3">

                          <DatePicker
                            selected={startDate}
                            onChange={onChange}
                            startDate={startDate}
                            endDate={endDate}
                            selectsRange
                            inline
                          />

                          <button className="btn mb-2 btn-primary  w-100" style={{width:'100%'}} onClick={cargarReportecapturistas}>Cargar Reporte</button>


                        </div>
                        <div className="col-12 col-md-9">

                                {
                                  envio_server && (
                                      <React.Fragment>
                                        Cargando...
                                      </React.Fragment>
                                    )
                                }


                                {
                                  !envio_server && (
                                      <React.Fragment>


                                            {
                                              reportecapturistas.length > 0 && (
                                                  <React.Fragment>

                                                    {
                                                      moment(startDate_seleccionada).format('YYYY-MM-DD') === moment(endDate_seleccionada).format('YYYY-MM-DD') && (
                                                          <>
                                                            <h4>{moment(startDate_seleccionada).format('DD [DE] MMMM [DE] YYYY').toUpperCase().replace('.','')}</h4>
                                                          </>
                                                        )
                                                    }
                                                    {
                                                      moment(startDate_seleccionada).format('YYYY-MM-DD') !== moment(endDate_seleccionada).format('YYYY-MM-DD') && (
                                                          <>
                                                            <h4>ENTRE {moment(startDate_seleccionada).format('DD [DE] MMMM [DE] YYYY').toUpperCase().replace('.','')} Y EL {moment(endDate_seleccionada).format('DD [DE] MMMM [DE] YYYY').toUpperCase().replace('.','')}</h4>
                                                          </>
                                                        )
                                                    }


                                                      <Table responsive="lg" size="sm">
                                                        <thead>
                                                          <tr>
                                                            {/*<th scope="col">Celular</th>*/}
                                                            <th scope="col" colSpan={2}>Capturista</th>
                                                            <th className="text-center" scope="col">👴🏻</th>
                                                            <th className="text-center" scope="col">♿️</th>
                                                            <th className="text-center" scope="col">Folios</th>
                                                            <th className="text-center" scope="col">Viviendas</th>
                                                          </tr>
                                                        </thead>
                                                        <tbody>
                                                          {
                                                            reportecapturistas.map((capturista:any,indice_capturista:number)=>

                                                                <tr key={indice_capturista}>
                                                                  {/*<th style={{width:150}} scope="row">{capturista.celular}</th>*/}
                                                                  <td className="v">
                                                                      <p className="nm"><b>{capturista.nombres}</b></p>
                                                                  </td>
                                                                  <td className="v">
                                                                      <small>{capturista.paterno} {capturista.materno}</small>
                                                                  </td>
                                                                  <td style={{width:60}} className="v text-center">{capturista.adultos}</td>
                                                                  <td style={{width:60}} className="v text-center">{capturista.discapacidad}</td>
                                                                  <td style={{width:60}} className="v text-center">{capturista.folios}</td>
                                                                  <td style={{width:60}} className="v text-center">{capturista.viviendas}</td>
                                                                </tr>

                                                              )
                                                          }
                                                        </tbody>
                                                      </Table>

                                              {
                                                // JSON.stringify(reportecapturistas)
                                              }

                                                  </React.Fragment>
                                                )
                                            }




                                            {
                                              reportecapturistas.length === 0 && (
                                                  <React.Fragment>
                                                      <div className="alert alert-warning" role="alert">
                                                        Para esta fecha no existe reporte
                                                      </div>
                                                  </React.Fragment>
                                                )
                                            }




                                      </React.Fragment>
                                    )
                                }

                        </div>
                    </div>


            </div>

          </>

      );
  

}

export default Censo_Reporte
