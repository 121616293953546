import React, { useState } from "react";
import AuthService from "../features/auth/auth.service";


import { Button, Modal } from 'react-bootstrap'

import PasswordChecklist from "react-password-checklist"

import Dialog from '../services/Dialogs'

import { toast } from 'react-toastify'

import RequestService from "../services/request.service";




import useFetch from '../services/useFetch';




import ModalCambiarFoto from './ModalCambiarFoto'


import { SERVER_ } from  '../config';



import { rol } from '../services/rol'



import './Profile.scss';


const Profile = () => {


    const VERSION_INSTALADA = localStorage.getItem("version_app");
    const [ VERSION_ACTUAL, serVersionActual ] = useState<string>('')

    const cargarVersionActual = async () =>{
      try{
          let res = await RequestService.getEndPoint('version_app')
          serVersionActual(res.data)

      }
      catch(e){
        toast.error('Problemas cargando la versión actual')
        toast.error(JSON.stringify(e))
      }
    }

    React.useEffect(() => {
      cargarVersionActual()
    }, []);









  const [password, setPassword] = useState("")
  const [passwordAgain, setPasswordAgain] = useState("")

  const dialog = new Dialog();

  const handleLogout = async () => {
    if(await dialog.confirm('Desear cerrar el acceso?',{}) ){
        AuthService.logout();
        window.location.reload();
    }
  }

  const currentUser  = AuthService.getCurrentUser();

  const [ ver_contrasena, setVerContrasena ] = useState<boolean>(false)

  const [show, setShow] = useState(false);
  const [send, setSend] = useState(false);

  const handleClose = () => setShow(false);
  const cambiarContrasenaModal = () =>{ 
      setPassword("")
      setPasswordAgain("")
      setShow(true);
  }
  
  const cambiarContrasena = async () =>{ 
      
      try{
          if(await dialog.confirm('¿Estás seguro?',{}) ){
              setShow(false);
              await RequestService.update('cambiar_password/'+currentUser.uuid,{ psw : password })
              toast.warning('Debes volver a entrar al sistema')
              setTimeout(()=>{
                  AuthService.logout();
                  window.location.reload();
              },1500)
          }
      }
      catch(e){
          toast.error('Error al cambiar la contraseña')
      }
  }




  const deleteCache = async ()=>{
    
          if(await dialog.confirm('¿Estás seguro?',{}) ){
                  localStorage.clear();
                  window.location.reload();
          }
  }

  



  const [ show_ModalCambiarFoto, setModalCambiarFoto ] = useState<boolean>(false)
  const handleShow_ModalCambiarFoto = () => {
    setModalCambiarFoto(true)
  }
  const handleClose_ModalCambiarFoto = () => {
    setModalCambiarFoto(false)
  }


      return (

        <React.Fragment>

            <div className="container">
              <div className="row">
                  <div className="col-lg-4 col-xl-4">
                      <div className="card-box text-center">

                             <div className="userMain">
                               <div className="userBlock">
                                   <div className="backgrounImg">
                                   </div>
                                   <div className="userImg">
                                       <img src={`${SERVER_}perfil/foto/${currentUser.uuid}`} onClick={handleShow_ModalCambiarFoto} />
                                   </div>
                                   <div className="userDescription">
                                       <h5>{currentUser.nombres}</h5>
                                       <p>{currentUser.uuid}</p>
                                   </div>


                                    <div className="text-left ms-3 me-3 mt-3 mb-3">

                                        <h6>Información de Usuario</h6>

                                        <p className="text-muted mb-2 font-13"><strong>Nombre : </strong> <span className="ml-2">{currentUser.nombres} {currentUser.paterno} {currentUser.materno}</span></p>
                                        <p className="text-muted mb-2 font-13"><strong>Celular : </strong><span className="ml-2">{(currentUser.celular)}</span></p>


                                        <p className="text-muted mb-2 font-13"><strong>Privilegios : </strong> <span className="ml-2 ">{rol(currentUser.rol)}</span></p>

                                        <p className="text-muted mb-2 font-13"><strong>Versión Instalada : </strong> <span className="ml-2 ">{VERSION_INSTALADA||`No podemos verificar la versión instalada `}</span></p>
                                        <p className="text-muted mb-2 font-13"><strong>Versión Actual : </strong> <span className="ml-2 ">{VERSION_ACTUAL}</span></p>



                                    </div>


                               </div>
                             </div>


                              <p className="nm mt-4"><small>Opciones</small></p>
                              <ul className="list-inline">
                                  <li className="list-inline-item" key={ Math.random().toString(36).substr(2, 9) }>
                                    <button onClick={()=>{cambiarContrasenaModal()}} className="btn btn-link">Cambiar Contraseña</button>
                                  </li>
                                  <li className="list-inline-item" key={ Math.random().toString(36).substr(2, 9) }>
                                    <button onClick={()=>{handleLogout()}} className="btn btn-link">Salir</button>
                                  </li>
                                  <li className="list-inline-item" key={ Math.random().toString(36).substr(2, 9) }>
                                    <button onClick={()=>{deleteCache()}} className="btn btn-link">Desinstalar</button>
                                  </li>
                              </ul>


                              <p className="mt-4 text-center">
                                <img src="./img/bienestar_logo_2024.png" width="40%" />
                              </p>

                                    
                      </div> 


                  </div> 
                  <div className="col-lg-8 col-xl-8">

                      {
                        // JSON.stringify(currentUser.rol)
                      }


                          <React.Fragment>

                            <h5>Módulos del Uso</h5>
                            <hr/>

                            <div className="row align-items-center">



                                  {
                                    (['ROL_AREA','ROL_REGION','ROL_MICROREGION','ROLE_ADMIN','ROL_ZONA'].indexOf(currentUser.rol) > -1) && (


                                          <div className="col-sm-4">
                                              <div className="card">
                                                <div className="card-body">
                                                  <h5 className="card-title">📒 Mis Folios</h5>
                                                  <p className="card-text">Mi Captura</p>
                                                  <a href="./mis_folios" className="btn btn-primary btn-tema">Entrar <i className="fa fa-sign-in" /></a>
                                                </div>
                                              </div>
                                          </div>

                                    )
                                  }

                                  {
                                    (['ROL_AREA'].indexOf(currentUser.rol) > -1) && (


                                          <div className="col-sm-4">
                                              <div className="card">
                                                <div className="card-body">
                                                  <h5 className="card-title">💾 Mi Avance</h5>
                                                  <p className="card-text">Reporte Personal</p>
                                                  <a href="./mi_avance" className="btn btn-primary btn-tema">Entrar <i className="fa fa-sign-in" /></a>
                                                </div>
                                              </div>
                                          </div>

                                    )
                                  }










                                  {
                                    (['ROLE_ADMIN'].indexOf(currentUser.rol) > -1) && (


                                          <div className="col-sm-4">
                                              <div className="card">
                                                <div className="card-body">
                                                  <h5 className="card-title">🌎 Posicionamiento</h5>
                                                  <p className="card-text">Mapa de Avance</p>
                                                  <a href="./posicionamiento" className="btn btn-primary btn-tema">Entrar <i className="fa fa-sign-in" /></a>
                                                </div>
                                              </div>
                                          </div>

                                    )
                                  }


                                  {
                                    (['ROL_ZONA'].indexOf(currentUser.rol) > -1) && (


                                          <div className="col-sm-4">
                                              <div className="card">
                                                <div className="card-body">
                                                  <h5 className="card-title"><i className="fa fa-users"/> Reporte</h5>
                                                  <p className="card-text">Reporte Individual</p>
                                                  <a href="./censo_reporte" className="btn btn-primary btn-tema">Entrar <i className="fa fa-sign-in" /></a>
                                                </div>
                                              </div>
                                          </div>

                                    )
                                  }

                                  {
                                    (['ROL_MICROREGION'].indexOf(currentUser.rol) > -1) && (


                                          <div className="col-sm-4">
                                              <div className="card">
                                                <div className="card-body">
                                                  <h5 className="card-title">📊 Avance</h5>
                                                  <p className="card-text">Reporte de Microregión</p>
                                                  <a href="./avance_microregion" className="btn btn-primary btn-tema">Entrar <i className="fa fa-sign-in" /></a>
                                                </div>
                                              </div>
                                          </div>

                                    )
                                  }
                                  {
                                    (['ROL_ZONA'].indexOf(currentUser.rol) > -1) && (


                                          <div className="col-sm-4">
                                              <div className="card">
                                                <div className="card-body">
                                                  <h5 className="card-title"><i className="fa fa-area-chart"/> Avance</h5>
                                                  <p className="card-text">Reporte de Zona</p>
                                                  <a href="./avance_zona" className="btn btn-primary btn-tema">Entrar <i className="fa fa-sign-in" /></a>
                                                </div>
                                              </div>
                                          </div>

                                    )
                                  }

                                  {
                                    (['ROLE_ADMIN','ROL_REGION','ROL_MICROREGION','ROL_ZONA'].indexOf(currentUser.rol) > -1) && (


                                          <div className="col-sm-4">
                                              <div className="card">
                                                <div className="card-body">
                                                  <h5 className="card-title">📋 Folios</h5>
                                                  <p className="card-text">Busqueda de Folios</p>
                                                  <a href="./censo_busqueda_folios" className="btn btn-primary btn-tema">Entrar <i className="fa fa-sign-in" /></a>
                                                </div>
                                              </div>
                                          </div>

                                    )
                                  }

                                  {
                                    (['ROLE_ADMIN'].indexOf(currentUser.rol) > -1) && (


                                          <div className="col-sm-4">
                                              <div className="card">
                                                <div className="card-body">
                                                  <h5 className="card-title">🌐 Secciones</h5>
                                                  <p className="card-text">Configuración</p>
                                                  <a href="./secciones" className="btn btn-primary btn-tema">Entrar <i className="fa fa-sign-in" /></a>
                                                </div>
                                              </div>
                                          </div>

                                    )
                                  }

                                  {
                                    (['ROLE_ADMIN'].indexOf(currentUser.rol) > -1) && (


                                          <div className="col-sm-4">
                                              <div className="card">
                                                <div className="card-body">
                                                  <h5 className="card-title">🫱🏼‍🫲🏽 Avance</h5>
                                                  <p className="card-text">Reporte de Situación</p>
                                                  <a href="./avances" className="btn btn-primary btn-tema">Entrar <i className="fa fa-sign-in" /></a>
                                                </div>
                                              </div>
                                          </div>

                                    )
                                  }


                                  {
                                    (['ROLE_ADMIN'].indexOf(currentUser.rol) > -1) && (


                                          <div className="col-sm-4">
                                              <div className="card">
                                                <div className="card-body">
                                                  <h5 className="card-title">🔑 Usuarios</h5>
                                                  <p className="card-text">Gestión de Accesos</p>
                                                  <a href="./usuarios" className="btn btn-primary btn-tema">Entrar <i className="fa fa-sign-in" /></a>
                                                </div>
                                              </div>
                                          </div>

                                    )
                                  }




                            </div>

                          </React.Fragment>






                  </div> 

              </div>
            </div>


          <Modal show={show} backdrop="static" onHide={handleClose}>
            <Modal.Header closeButton>
              <Modal.Title>Cambiar de Contraseña</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                
                  <form>
                      <div className="form-group mb-3">
                          <label className="form-label">Password:</label>
                          <input className="form-control" type={!ver_contrasena?'password':'text'} onChange={e => setPassword(e.target.value)} />
                      </div>
                      <div className="form-group mb-3">
                          <label className="form-label">Repetir Password:</label>
                          <input className="form-control" type={!ver_contrasena?'password':'text'} onChange={e => setPasswordAgain(e.target.value)} />
                      </div>

                    <div className="form-check">
                      <input className="form-check-input" type="checkbox" id="ver_contra" checked={ver_contrasena} onChange={(e:any)=>{setVerContrasena(e.target.checked)}}  />
                      <label className="form-check-label" htmlFor="ver_contra"><small>Ver Contraseñas</small></label>
                    </div>

                    <br/>

                      <PasswordChecklist
                          rules={["minLength","specialChar","number","capital","match"]}
                          messages={{
                              minLength: "La contraseña tiene más de 8 carácteres.",
                              specialChar: "La contraseña debe contener carácteres especiales (* - , . ´)",
                              number: "La contraseña debe tener un número.",
                              capital: "La contraseña debe tener una letra mayúscula.",
                              match: "Las contraseñas coinciden.",
                          }}
                          minLength={8}
                          value={password}
                          valueAgain={passwordAgain}
                          onChange={(isValid) => {
                              setSend(!isValid)
                          }}
                      />
                  </form>

            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose}>
                Cancelar
              </Button>
              <Button variant="primary" disabled={send} onClick={cambiarContrasena}>
                Guardar Cambios
              </Button>
            </Modal.Footer>
          </Modal>









  {
    show_ModalCambiarFoto && (
      <ModalCambiarFoto show={show_ModalCambiarFoto} handleClose={handleClose_ModalCambiarFoto} usuario={currentUser} />
      )

  }


        </React.Fragment>


      );
    
}

export default Profile
